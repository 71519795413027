import React, { Component } from 'react';
import { Row, Col } from 'antd';

export class FaqPage extends Component {

  componentDidMount() {
    // if 'toTop' is set, scroll to top on window
    try {
      const { toTop } = this.props.location.state; 
      if(toTop) {
        window.scrollTo(0,0);
      }
    } catch(e) {
      // do nothing. 
    }
  }

  spacesToUnderscore(theString) {
    return theString.replace(/ /g, "_");
  }

  renderQuestionSection(question, answer, pt2) {
    return (
      <React.Fragment>
        <Row style={{ paddingLeft : '0.5rem', paddingRight: '0.5rem' }}>
          <h2 id={`${this.spacesToUnderscore(question)}`} style={{ color: 'rgb(91, 18, 19)', fontWeight: "bold" }}>
            <span> {question} </span>
            <a href={`#${this.spacesToUnderscore(question)}`}>*</a>
          </h2>
        </Row>
        <Row type="flex" justify="space-around" align="middle" style={{ paddingLeft : '0.5rem', paddingRight: '0.5rem' }}>
          <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <p> {answer} </p>
            {pt2 &&
              <p> {pt2} </p>
            }
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                Frequently Asked Questions
              </h1>
            </div>
          </Col>
        </Row>

        {this.renderQuestionSection("What is Notary Match?", "Notary Match is an online platform that allows any person or business to get their documents legally notarized by a Notary.")}
        {this.renderQuestionSection("How do I get my documents notarized through Notary Match? ", "Click Request a Notary on our website. Fill out all the information needed and choose what type of document you need notarized. Click Submit once you’re done reviewing all your information and your estimated cost will be displayed below.", "A certified Notary will call you within 10-15 minutes to discuss your needs in detail and to set up a time and place to meet.")} 
        {this.renderQuestionSection("How much does it cost to get a document notarized on Notary Match?", "The cost varies on the type of document needed notarized, timeliness of the request, and other costs such as printing charges, copies made, delivery and mailing etc..", "An estimated charge is computed on the website when you request a notary.")}
        {this.renderQuestionSection("What types of documents can I get notarized?", "Notary Match will pair you with a licensed and bonded Notary that can notarize documents such as Power of Attorney, Will, Lease Agreement, Loan Signing, Statement of Consent, Deed of Trust, Contract, Bill of Sales, Promissory Note, etc... ")}
        {this.renderQuestionSection("How long does it usually take for a notary to call me after submitting a request?", "The average waiting time is 10-15 minutes.")}
        {this.renderQuestionSection("How long does it usually take for me to get my documents notarized?", "The turnaround time depends on your requirements and timeline. Once your request is processed, a notary will call you to discuss the details of your needs.")}
        {this.renderQuestionSection("I submitted a request but did not get a call. How can I follow up? ", "Once a request is submitted, a notary will call you within 10-15 minutes. If you have not received a call from one of our notaries after the given time, please email info@notarymatch.io")}
        {this.renderQuestionSection("Can I cancel my request?", "Yes. You will receive a refund of your payment subject to a 10% cancellation fee if you cancel within 24 hours after submitting a request.")}
        {this.renderQuestionSection("I can’t make it to my appointment. Can I reschedule?", "Yes. Contact your notary to reschedule your appointment.")}
        {this.renderQuestionSection("My document has been notarized and has an error. What should I do?", "Contact your notary for any problems regarding your notarized document.")}
        {this.renderQuestionSection("I can’t reach my Notary. Who can I talk to?", "If you are unable to reach your notary please email info@notarymatch.io.")}
        {this.renderQuestionSection("What payment methods does Notary Match accept?", "We accept debit cards and major credit cards: Visa, MasterCard, Discover, and American Express.")}
        {this.renderQuestionSection("When is payment due?", "Payment is due after the appointment is set.")}
        {this.renderQuestionSection("Is my information safe with Notary Match?", "Notary match takes your security and privacy very seriously. You can review our Terms of service and Privacy Policy.")}
      </div>
    );
  }
}
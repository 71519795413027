import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
// import { Redirect } from "react-router-dom";
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import { docTypeDisplay } from '../Util/display'
import { defaultHeaderWithApiKey } from '../Util/http'

import config from '../config';

export class PaymentPage extends Component {
  constructor(props) {
    super(props);

    try {
      // let queryParams = new URLSearchParams(props.location.search);
      const invoiceId = this.extractInvoiceId(props.location.search);

      if(invoiceId) {
        this.state = {
          invoiceId: invoiceId,
          showError: false
        }
      } 
      else {
        this.state = {
          showError: true
        }
      }

    } catch (e) {
      console.error(e);
      this.state = {
        showError: true
      }
    }
  }

  extractInvoiceId(queryParams) {
    /**
     * check if first two chars are '?i='
     * check if remaining chars.length == 32
     * return remaining chars
     */

    const firstThree = queryParams.slice(0,3);
    const remaining = queryParams.slice(3);
    return firstThree === "?i=" && remaining.length === 32 ? remaining : null;
  }

  render() {

    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                Pay for Appointment
              </h1>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 12 }}>
            {this.state.showError &&
              <ErrorPanel />
            }
            {!this.state.showError &&
              <PaymentPanel invoiceId={this.state.invoiceId} />
            }
          </Col>
        </Row>
      </div>
    );
  }
}

class PaymentPanel extends Component {
  state = {
    invoice: {}
  }

  componentDidMount() {
    // get invoice from payment service
    const url = `${config.paymentServiceUrl}/getInvoice?invoiceId=${this.props.invoiceId}`;
    axios.get(url, defaultHeaderWithApiKey(config.paymentServiceKey))
      .then((res) => {
        this.setState({
          invoice: res.data.invoice
        })
      })
      .catch((e) => {
        console.error(e);
      })
  }

  render() {

    const { invoice } = this.state;

    if(!invoice) {
      return (
        <div>Loading...</div>
      );
    }

    return (
      <div>
        <Row>
          Your notary, <strong> {invoice.notaryName} </strong> , has scheduled your appointment. If any details 
          are incorrect, contact your notary at <a href={`"tel:${invoice.notaryPhone}`} style={{ textDecoration: 'underline' }} >{invoice.notaryPhone}</a> to correct it.
        </Row>
        {/* <Row style={{ paddingTop: '1rem' }}>
          <Col span={24}>
            <span style={{ fontSize: "x-large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
              Appointment Details 
            </span>
          </Col>
        </Row> */}
        <Row style={{ textAlign: 'left', paddingTop: '1rem', paddingLeft: '1rem' }}>

        <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem', paddingRight: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Request Id
              </span>
            </div>
            <div>
              {invoice.requestId}
            </div>
          </Col>

          <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem', paddingRight: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Your Name
              </span>
            </div>
            <div>
              {invoice.customerName}
            </div>
          </Col>

          <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Email Address
              </span>
            </div>
            <div>
              {invoice.customerEmail}
            </div>
          </Col>

          <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem', paddingRight: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Appointment Address
              </span>
            </div>
            <div>
              <a 
                target="_blank"
                rel="noopener noreferrer"
                href={`http://maps.google.com/?q=${invoice.apptFormattedAddress}`}
                style={{ textDecoration: 'underline', color: 'black' }}
              >
                {invoice.apptFormattedAddress}
              </a>
            </div>
          </Col>

          <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Appointment Time
              </span>
            </div>
            <div>
              {invoice.apptFormattedDatetime}
            </div>
          </Col>

          <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem', paddingRight: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Base Price
              </span>
            </div>
            <div>
              {`Notarize ${docTypeDisplay(invoice.docType)}:`} <strong>{`$${invoice.basePrice}.00`}</strong> 
            </div>
          </Col>

          <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem', paddingRight: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Notarized Signatures
              </span>
            </div>
            <div>
              {`${invoice.signatureCount} - Notarized Signatures:`} <strong> {invoice.signaturePrice === 0 ? 'INCLUDED' : `$${invoice.signaturePrice}.00`} </strong>
            </div>
          </Col>

          {invoice.additionals && invoice.additionals.length > 0 &&
          <Col xs={{ span: 24 }} style={{ paddingBottom: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Additional Services
              </span>
            </div>
            <div>
              {invoice.additionals.map((add, i) => {
                return (
                  <div key={i}>
                    <span>{add.label}: </span>
                    <strong><span> ${add.price}.00 </span></strong>
                  </div>
                );
              })}
            </div>
            {/* <div>
              <pre>
                {JSON.stringify(invoice, null, 4)}
              </pre>
            </div> */}
          </Col>
          }

          <Col xs={{ span: 24 }} md={{ span: 12 }} style={{ paddingBottom: '1rem' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Total Price
              </span>
            </div>
            <div>
            <strong>${invoice.totalPrice}.00</strong>
            </div>
          </Col>

        </Row>
        
        {/* <div style={{ textAlign: 'left', paddingTop: '5rem' }}>
          <pre>
            {JSON.stringify(invoice, null, 4)}
          </pre>
        </div> */}
        <Row style={{ paddingBottom: '2rem' }}>
          <StripeCheckout
            token={this.onToken.bind(this)}
            stripeKey={config.stripePublicKey}
            name="Notary Match Appointment"
            email={invoice.customerEmail}
            allowRememberMe={false}
            currency="USD"
            amount={invoice.totalPrice * 100}
            zipCode={true}
            billingAddress={true}
          >
            <Button type="primary">
              Pay for Appointment
            </Button>
          </StripeCheckout>
        </Row>
      </div>
    );
  }

  onToken(token) {
    /**
     * TODO
     * 
     * handle error cases
     * 
     */

    const url = `${config.paymentServiceUrl}/submitPayment`;
    axios.post(url, {
      tokenId: token.id,
      invoiceId: this.state.invoice.invoiceId
    }, defaultHeaderWithApiKey(config.paymentServiceKey))
      .then((res) => {
        alert(`Your payment has been submitted and your notary has been notified to proceed with your appointment. We appreciate your business.`);
      })
      .catch((e) => {
        console.error(e);
        alert(`Your payment was unable to be processed. Please re-enter a valid card.`);
      })
  }
}

class ErrorPanel extends Component {

  render() {

    return (
      <div >
        ErrorPanel
      </div>
    );
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { Icon, Input } from 'antd';

class PhoneNumberInput extends Component {

    render() {
        return (
            <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                guide={false}
                showMask={true}
                render={(ref, props) => <Input ref={(input) => ref(input && input.input)} prefix={<Icon type="phone" />} {...props} />}
                {...this.props}
            />
        );
    }
}

PhoneNumberInput.propTypes = {
    onChange: PropTypes.func,
    'data-__field': PropTypes.object,
    value: PropTypes.string,
};

export default PhoneNumberInput;
import React, { Component } from 'react';
import { Row, Col } from 'antd';

export class HowItWorksPage extends Component {

  componentDidMount() {
    // if 'toTop' is set, scroll to top on window
    try {
      const { toTop } = this.props.location.state;
      if (toTop) {
        window.scrollTo(0, 0);
      }
    } catch (e) {
      // do nothing. 
    }
  }
  
  getBody() {
    return (
      <Row type="flex" justify="space-around" align="middle" style={{ paddingBottom: '1rem' }}>
        <Col sm={{ span: 14 }} style={{ paddingBottom: '2rem' }}>
          <img src="notary.png" alt="" />
          <h3 className="nm-hiw-heading" >Request a notary</h3>
          <p style={{ color: 'rgb(91, 18, 19)' }} >Fill out all the information needed and choose what type of document you need  notarized.</p>
        </Col>
        <Col sm={{ span: 14 }} style={{ paddingBottom: '2rem' }}>
          <i class="fa-8x far fa-calendar-check" style={{ color: 'rgb(91, 18, 19)' }}></i>
          <h3 className="nm-hiw-heading">Set an appointment with in your notary</h3>
          <p class="sec" style={{ color: 'rgb(91, 18, 19)' }}>A Notary will call you to set an appointment and discuss the details of your request.</p>
          </Col>
        <Col sm={{ span: 14 }} style={{ paddingBottom: '2rem' }}>
          <img src="pay.png" alt="" />
          <h3 className="nm-hiw-heading">Pay</h3>
          <p style={{ color: 'rgb(91, 18, 19)' }}>An invoice will be sent to your email. Payment due upon  receipt of invoice.</p>
        </Col>
        <Col sm={{ span: 14 }} style={{ paddingBottom: '2rem' }}>
          <img src="pen.png" alt="" />
          <h3 className="nm-hiw-heading">Get your documents notarized</h3>
          <p class="last" style={{ color: 'rgb(91, 18, 19)' }}>Meet with your Notary at the appointed time to get your documents notarized.</p>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div>
        <Row type="flex" justify="space-around" align="middle" style={{ paddingBottom: '1rem' }}>
          <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                How It Works
              </h1>
            </div>
          </Col>
        </Row>
        {this.getBody()}
      </div>
    );
  }
}
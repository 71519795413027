import React, { Component } from 'react';
import NMNavbar from './Components/NavBar/NMNavBar';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { RequestPage } from './Pages/RequestPage';
import { SuccessfulPage } from './Pages/SuccessfulPage';
import { PaymentPage } from './Pages/PaymentPage';
import { HowItWorksPage } from './Pages/HowItWorksPage';
import { FaqPage } from './Pages/FaqPage';
import NMFooter from './Components/Footer/NMFooter';

class App extends Component {

  state = {
    redirectHome: false,
    redirectWorks: false,
    redirectFaq: false
  }

  componentDidUpdate() {
    // if redirectHome == true, flip it so the home link will work
    if(this.state.redirectHome) {
      this.setState({
        redirectHome: false
      })
    }
    // if redirectWorks == true, flip it so the link will work
    if(this.state.redirectWorks) {
      this.setState({
        redirectWorks: false
      })
    }
    // if redirectFaq == true, flip it so the link will work
    if(this.state.redirectFaq) {
      this.setState({
        redirectFaq: false
      })
    }
  }

  homeRedirect() {
    this.setState({
      redirectHome: true
    })
  }

  worksRedirect() {
    this.setState({
      redirectWorks: true
    })
  }

  faqRedirect() {
    this.setState({
      redirectFaq: true
    })
  }

  render() {
    return (
      <Router>
        {this.state.redirectHome && <Redirect to='/' push={true}/>}
        {this.state.redirectWorks && <Redirect to='/works' push={true}/>}
        {this.state.redirectFaq && <Redirect to='/faq' push={true}/>}

        <div className="App">
          <header className="App-header">
            <NMNavbar 
              homeRedirect={this.homeRedirect.bind(this)}
              worksRedirect={this.worksRedirect.bind(this)}
              faqRedirect={this.faqRedirect.bind(this)}
            />
          </header>
          <Switch>
            <Route
              exact
              path="/"
              render={props => <RequestPage {...props}/> }
            />
            <Route 
              exact
              path="/requested"
              render={props => <SuccessfulPage {...props}/> }
            />
            <Route 
              exact
              path="/payment"
              render={props => <PaymentPage {...props}/> }
            />
            <Route 
              exact
              path="/works"
              render={props => <HowItWorksPage {...props}/> }
            />
            <Route 
              exact
              path="/faq"
              render={props => <FaqPage {...props}/> }
            />
          </Switch>          
          <NMFooter />
        </div>
      </Router>
    );
  }
}

export default App;

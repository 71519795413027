import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button, Checkbox, Select, Modal } from 'antd';
import axios from 'axios';
import PhoneNumberInput from '../Components/PhoneNumberInput'
import Geosuggest from 'react-geosuggest';
import { Redirect } from "react-router-dom";
import { defaultHeaderWithApiKey } from '../Util/http'
import { allDocTypeSelection } from '../Util/display'

import config from '../config';

export class RequestPage extends Component {

	state = {
    showEdit: false,
    showComplete: false
  }

  componentDidMount() {
    // if 'toTop' is set, scroll to top on window
    try {
      const { toTop } = this.props.location.state; 
      if(toTop) {
        window.scrollTo(0,0);
      }
    } catch(e) {
      // do nothing. 
    }
  }

  render() {
    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                Request A Notary
              </h1>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <AntdRequestForm />
          </Col>
        </Row>
      </div>
    );
  }
}

class RequestForm extends Component {

  state = {
    requestSubmitted: false,

    // redirect to successfully requested
    redirectToSuccess: false,
    successParams: {},

    // form values
    values : {},
    meetupAddress : '',

    // flag to enable/disable submit
    metroAreaIsAvailable : true,

    // modal flags
    showMetroModal : false,
    showSubmitModal : false,

    // price estimate
    estimateDocType: '',
    estimateMetroArea: '',
    estimatePrice: 0,

    // dropdown options
    docTypeOptions : allDocTypeSelection(),
    copiesOptions : [
      { key: 1 , value: '1' }, { key: 2 , value: '2' },
      { key: 3 , value: '3' }, { key: 4 , value: '4' },
      { key: 5 , value: '5' }
    ],
    metroOptions : [
      { key: 'SEA' , value: 'Greater Seattle Area' },
      { key: 'OTHER' , value: 'Other' }
    ]
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.estimateDocType || !this.state.estimateMetroArea) {
      return;
    }
    // removed estimates
  }

  metroOnChange(metroOption) {
    if('OTHER' === metroOption) {
      this.setState({
        estimateMetroArea : metroOption,
        metroAreaIsAvailable : false,
        showMetroModal: true
      });
    } else {
      this.setState({
        estimateMetroArea : metroOption,
        metroAreaIsAvailable : true
      });
    }
  }

  docTypeOnChange(selectedDoc) {
    this.setState({
      estimateDocType : selectedDoc
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const values = this.props.form.getFieldsValue();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(this.state.meetupAddress != undefined && this.state.meetupAddress.length > 0) {
          values.meetupAddress = this.state.meetupAddress
        }
        this.setState({
          values: values,
          showSubmitModal: true
        });
      } 
      else {
        console.error(err);
      }
    });
  };


  render() {
    if(this.state.redirectToSuccess) {
      return <Redirect to={{pathname: "/requested", state: this.state.successParams }} push={true}/>;
    }

    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="request-form">
        <Form.Item>
          {getFieldDecorator('firstName', {
            rules: [{ required: true, message: 'Please input your first name' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="First Name"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('lastName', {
            rules: [{ required: true, message: 'Please input your last name' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Last Name"
            />,
          )}
        </Form.Item>
        {/* <Form.Item>
          {getFieldDecorator('phoneNo', {
            rules: [{ required: true, message: 'Please input your phone number' }],
          })(

            <Input
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="My Phone #"
              size="large"
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            />,
          )}
        </Form.Item> */}

        <Form.Item>
          {getFieldDecorator('phoneNo', {
            rules: [{ required: true, message: 'Please input your phone number' }],
          })(
            <PhoneNumberInput placeholder="Enter your phone number" maxLength={14} />,
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { type: 'email', message: 'The input is not a valid email address' },
              { required: true, message: 'Please input your email address' }],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email Address"
            />,
          )}
        </Form.Item>

        <Form.Item>
          <div>
            Select the document that you need notarized
          </div>
          {getFieldDecorator('docType', {
            rules: [{ required: true, message: 'Please select a document' }],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder="Please select"
              onChange={this.docTypeOnChange.bind(this)}
            >
              {this.state.docTypeOptions.map(option => (<Select.Option key={option.key}> {option.value} </Select.Option>))}
            </Select>,
          )}
        </Form.Item>
        {/* <Form.Item>
          <div>
            How many copies need to be notarized?
          </div>
          <Select
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={this.copiesOnChange}
            defaultValue={1}
          >
            {this.state.copiesOptions.map(option => (<Select.Option key={option.key}> {option.value} </Select.Option>))}
          </Select>
        </Form.Item> */}
        <Form.Item>
          <div>
            Select your Metropolitian Area
          </div>
          {getFieldDecorator('metroArea', {
            rules: [{ required: true, message: 'Please select a metropolitian area' }],
          })(
            <Select
              style={{ width: '100%' }}
              placeholder="Please select"
              onChange={this.metroOnChange.bind(this)}
            >
              {this.state.metroOptions.map(option => (<Select.Option key={option.key}> {option.value} </Select.Option>))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item>
          <div>
            Input your preferred meet up location (Optional)
          </div>
            <Geosuggest
              ref={el=>this._geoSuggest=el}
              inputClassName="ant-input ant-input-lg"
              suggestsClassName="ant-select-dropdown-menu ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical"
              onChange={(value) => {
                this.setState({
                  meetupAddress: value
                })
              }}
              onSuggestSelect={(suggest) => {
                if(suggest !== undefined && suggest.gmaps !== undefined) {
                  this.setState({
                    meetupAddress: suggest.gmaps.formatted_address
                  })
                }
              }}
              country="US"
              placeDetailFields={['formatted_address', 'geometry']}
            />
        </Form.Item>

        <Form.Item>
          <div>
            {this.state.estimateMetroArea && this.state.estimateMetroArea != 'OTHER' && this.state.estimateDocType &&
              <strong> Fee to be determined by Notary </strong>
            }
          </div>
        </Form.Item>

        <Form.Item>
          <Button disabled={!this.state.metroAreaIsAvailable} type="primary" htmlType="submit" className="nm-request-submit">
            Submit
          </Button>
        </Form.Item>
        <Modal
          visible={this.state.showMetroModal}
          title="Unfortunate News"
          onCancel={this.onMetroDismiss}
          footer={[
            <Button key="metroDismiss" type="primary" onClick={this.onMetroDismiss}>
              Dismiss
            </Button>,
          ]}
        >
          <p> Notary Match hasn't been rolled out in your area. We are working on expanding to new Metropolitan Areas.</p>
          <p> Feel free to express your questions or concerns via <a>Contact Us</a>. </p>
        </Modal>
        <Modal
          visible={this.state.showSubmitModal}
          title="Submit Request for Notary"
          onCancel={this.onSubmitDismiss}
          footer={[
            <Button key="submitDismiss" onClick={this.onSubmitDismiss}>
              No
            </Button>,
            <Button key="submitYes" type="primary" onClick={this.onSubmitYes}>
              Yes
            </Button>,
          ]}
        >
          <p>
            By submitting your request you agree to Notary Match's <a href='https://notarymatch.s3-us-west-2.amazonaws.com/Terms+of+Use+and+Privacy+Policy.pdf' target='_blank'>Terms of Service</a>.
          </p>
          <p>
            Once your request is processed, a certified Notary will contact you 
            at <span style={{ color:'rgb(91, 18, 19)', fontWeight: 'bolder' }} >{this.state.values.phoneNo}</span> to schedule an appointment.
            Would you like to proceed?
          </p>
        </Modal>
      </Form>
    );
  }

  onMetroDismiss = () => {
    this.setState({
      showMetroModal: false
    });
  };

  onSubmitDismiss = () => {
    this.setState({
      showSubmitModal: false
    });
  };

  onSubmitYes = async () => {

    if (this.state.requestSubmitted) {
      return;
    }

    this.setState({requestSubmitted : true})

    // submit request to request service
    const { firstName, lastName, phoneNo, email, docType, metroArea, meetupAddress } = this.state.values;

    const requestBody = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNo: phoneNo,
      docType: docType,
      metroArea: metroArea,
      meetupAddress: meetupAddress,
      estimatePrice: this.state.estimatePrice
    };

    const url = `${config.requestServiceUrl}/createRequest`;
    const response = await axios.post(url, requestBody, defaultHeaderWithApiKey(config.requestServiceKey))

    this.setState({
      showSubmitModal: false
    });

    // take them to succesful request page
    const { requestId, createdAt} = response.data.item;
    
    this.setState({
      redirectToSuccess: true,
      successParams: {
        firstName: firstName,
        lastName: lastName,
        phoneNo: phoneNo,
        requestId: requestId,
        createdAt: createdAt
      }
    });
  }

}

const AntdRequestForm = Form.create({ name: 'nm_request' })(RequestForm);
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Redirect } from "react-router-dom";

export class SuccessfulPage extends Component {
  render() {

    if(this.props.location.state === undefined) {
      return <Redirect to='/'/>;
    }

    const { firstName, lastName, phoneNo, requestId, createdAt } = this.props.location.state;
    
    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                Request Submitted
              </h1>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <SuccessfulPanel 
              name={firstName + " " + lastName} 
              phoneNo={phoneNo}
              requestId={requestId}
              createdAt={createdAt}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class SuccessfulPanel extends Component {

  render() {

    const { name, phoneNo, requestId, createdAt } = this.props;

    return (
      <div >
        <Row>
          Your request has been submitted and is being processed. 
          A notary will contact you in 10-15 minutes at <span style={{ fontWeight: "700", color: "#5B1213" }}> {phoneNo} </span> to schedule an appointment.
        </Row>
        <Row style={{ textAlign: 'center', paddingTop: '1em' }}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Request Id
              </span>
            </div>
            <div>
              {requestId}
            </div>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Request Time
              </span>
            </div>
            <div>
              {new Date(createdAt).toLocaleString()}
            </div>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Name
              </span>
            </div>
            <div>
              {name}
            </div>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500", letterSpacing: "0.125em", color: "#5B1213" }}>  
                Phone Number
              </span>
            </div>
            <div>
              {phoneNo}
            </div>
          </Col>

        </Row>
      </div>
    );
  }
}
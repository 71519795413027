import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Footer from 'rc-footer';

class NMFooter extends Component {

  render() {
    return (
      <Footer
        prefixCls="nm-ftr"
        className="ftr"
        backgroundColor='#B3ABA9'
        columnLayout='space-between'
        columns={[
          {
            title: (
              <span style={{ fontFamily: '"Arial Black", Gadget, sans-serif', color: 'rgb(91, 18, 19)', fontWeight: 'bolder' }}>
                NM
              </span>
            )
          },
          {
            className: "ant-col ant-col-xs-24 ant-col-sm-8",
            items: [
              {
                icon: (
                  <Link
                    to={{
                      pathname: "/faq",
                      state: { toTop: true }
                    }}
                  > FAQ</Link>
                )
              }
            ],
          },
          {
            className: "ant-col ant-col-xs-24 ant-col-sm-8",
            items: [
              {
                icon: (
                  <Link
                    to={{
                      pathname: "/works",
                      state: { toTop: true }
                    }}
                  > How it Works</Link>
                )
              }
            ],
          },
          {
            className: "ant-col ant-col-xs-24 ant-col-sm-8",
            items: [
              {
                title: 'Terms of Service',
                url: 'https://notarymatch.s3-us-west-2.amazonaws.com/Terms+of+Use+and+Privacy+Policy.pdf',
                openExternal: true,
              }
            ],
          },
          {
            items: [
              {
                icon: (
                  <Link
                    to={{
                      pathname: "/",
                      state: { toTop: true }
                    }}
                  > Request a Notary</Link>
                )
              }
            ],
          }, 
        ]}
        bottom={(
        <div style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }} >
          <span style={{color: 'white'}}> {"© Copyright 2020 Notary Match"}</span>
        </div>
        )}
      />
    )
  }
}

export default NMFooter;
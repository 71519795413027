
const defaultConfigs = {
  requestServiceUrl: process.env.REACT_APP_REQUEST_SERVICE_URL,
  pricingServiceUrl: process.env.REACT_APP_PRICING_SERVICE_URL,
  paymentServiceUrl: process.env.REACT_APP_PAYMENT_SERVICE_URL,

  requestServiceKey: process.env.REACT_APP_REQUEST_SERVICE_KEY,
  pricingServiceKey: process.env.REACT_APP_PRICING_SERVICE_KEY,
  paymentServiceKey: process.env.REACT_APP_PAYMENT_SERVICE_KEY,

  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY
};

export default defaultConfigs;

import React, { Component } from 'react';
import { Drawer, Button, Menu, Modal } from 'antd';
// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

class NMNavbar extends Component {
	state = {
    visible: false,
    contactModalVisible: false
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  showContact = () => {
    this.setState({
      contactModalVisible: true,
    });
  };

  dismissContact = () => {
    this.setState({
      contactModalVisible: false
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {

    const rightMenu = (mode) => {
      return <RightMenu 
        mode={mode}
        isLoggedIn={this.props.isLoggedIn}
        worksRedirect={this.props.worksRedirect}
        faqRedirect={this.props.faqRedirect}
        showContact={this.showContact.bind(this)}
        onClose={this.onClose.bind(this)}
         />;
    };

    return (
      <nav className="nm-nav-menu-bar">
        <div 
          className="nm-nav-logo"
          onClick={this.props.homeRedirect}
        >
          <span style={{ fontFamily: '"Arial Black", Gadget, sans-serif', fontSize: 'x-large', color: 'rgb(91, 18, 19)', fontWeight: 'bolder' }}>
              NM
          </span>
        </div>
        <div className="nm-nav-menu-container">
          <div className="nm-nav-right-menu">
            {rightMenu("horizontal")}
          </div>
          <Button className="nm-nav-bars-menu" type="primary" onClick={this.showDrawer}>
            <span className="nm-nav-bars-btn"></span>
          </Button>
          <Drawer
            title="Menu"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            {rightMenu("inline")}
          </Drawer>
        </div>
        <Modal
          visible={this.state.contactModalVisible}
          title="Contact Us"
          onCancel={this.dismissContact}
          footer={[
            <Button key="dismissContact" type="primary" onClick={this.dismissContact}>
              Dismiss
            </Button>,
          ]}
        >
          <p> If you have any questions or concerns about Notary Match, you can reach us at the following.</p>
          <p> <a href="mailto:info@notarymatch.io?subject=Notary Match Inquiry" target="_blank">Email Us</a> at: info@notarymatch.io </p>
        </Modal>
      </nav>
    );
  }
}

class RightMenu extends Component {
  render() {
    return (
      <Menu 
        selectable={false}
        mode={this.props.mode}
        onClick={( item ) => {
          const { key } = item;
          
          switch(key) {
            case "works":    
              this.props.worksRedirect();
              break;  
            case "faq":
              this.props.faqRedirect();
              break;
            case "contact":
              this.props.showContact();
              break;    
            default:
              return;
          }

          this.props.onClose();
        }}
      >
        <Menu.Item key="works">
          How it Works
        </Menu.Item>
        <Menu.Item key="faq">
          FAQ
        </Menu.Item>
        <Menu.Item key="contact">
          Contact Us
        </Menu.Item>
      </Menu>
    );
  }
}


export default NMNavbar;